<template>
  <v-data-table
    :items="items"
    :headers="headers"
    :items-per-page="itemsPerPage"
    class="elevation-1 fill-height"
    hide-default-footer
  >
    <template v-slot:item.status="{ item }">
      <activity-table-status-cell :status="item.status" />
    </template>
    <template v-slot:item.item="{ item } ">
      <activity-table-item-cell :item="item" />
    </template>
    <template v-slot:item.ethPrice="{ item }">
      <currency-display token="eth" :value="item.ethPrice" class="text-center" />
    </template>
    <template v-slot:item.from="{ item }">
      {{ item.from }}
    </template>
    <template v-slot:item.to="{ item }">
      {{ item.to }}
    </template>
  </v-data-table>
</template>

<script>
import CurrencyDisplay from '@/features/currency-display/CurrencyDisplay.vue';
import ActivityTableItemCell from './cells/ActivityTableItemCell.vue';
import ActivityTableStatusCell from './cells/ActivityTableStatusCell.vue';

export default {
  name: 'ActivityTable',
  components: {
    CurrencyDisplay,
    ActivityTableItemCell,
    ActivityTableStatusCell,
  },
  props: {
    items: {
      type: Array,
      required: true,
      default: () => ([]),
    },
    itemsPerPage: {
      type: [String, Number],
      required: false,
      default: () => (10),
    },
  },
  data() {
    return {
      headers: [
        {
          text: '',
          align: 'center',
          value: 'status',
        },
        {
          text: 'Item',
          align: 'center',
          value: 'item',
        },
        {
          text: 'Price',
          align: 'center',
          sortable: true,
          value: 'ethPrice',
        },
        {
          text: 'From',
          align: 'center',
          value: 'from',
        },
        {
          text: 'To',
          align: 'center',
          value: 'to',
        },
        {
          text: 'Time',
          align: 'center',
          sortable: true,
          value: 'time',
        },
      ],
    };
  },
};
</script>
