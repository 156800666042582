<template>
  <section class="activity-table__cell--item d-inline-flex py-3">
    <v-card height="50" width="50" rounded="24">
      <v-img :src="item.image" alt="" height="50" width="50" rounded contain />
    </v-card>
    <section class="ml-3">
      <p
        class="mb-0 body-2 font-weight-medium text--secondary">
        <small>
          {{ item.collection }}
        </small>
      </p>
      <p class="subtitle-2 font-weight-bold">{{ item.title }}</p>
    </section>
  </section>
</template>

<script>
export default {
  name: 'ActivityTableItemCell',
  props: {
    item: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
};
</script>
