<template>
  <section>
    <span class="d-inline-flex align-center">
      <!-- Cryptocurrency Icon Here -->
      <v-icon left color="black" small>$ethereumIcon</v-icon>
      <!-- Cryptocurrency Icon Here -->
      <p class="subtitle-1 mb-0 font-weight-bold">0.1</p>
    </span>
    <p class="subtitle-2 text--secondary mb-0">{{ cryptoCurrencyValue | toCurrency }}</p>
  </section>
</template>

<script>
export default {
  name: 'CurrencyDisplay',
  props: {
    token: {
      type: String,
      required: true,
      default: () => (''),
    },
    value: {
      type: [Number, String],
      required: true,
      default: () => (''),
    },
  },
  data: () => ({
    cryptoValueMap: {
      eth: 4238.44,
    },
  }),
  computed: {
    cryptoCurrencyValue() {
      return Object.keys(this.cryptoValueMap).includes(this.token)
        ? Number(this.value) * this.cryptoValueMap[this.token]
        : null;
    },
  },
};
</script>
