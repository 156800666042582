<template>
  <span class="d-flex justify-center">
    <v-icon small left class="text--secondary">{{ icon }}</v-icon>
    {{ status }}
  </span>
</template>

<script>
export default {
  name: 'ActivityTableStatusCell',
  props: {
    status: {
      type: String,
      required: true,
      default: () => (''),
      validator: (val) => ['list', 'minted', 'sale,', 'transfer'].includes(val),
    },
  },
  computed: {
    icon() {
      const iconMap = {
        list: 'mdi-tag',
        minted: 'mdi-baby-carriage',
        default: 'mdi-tag',
      };

      return Object.keys(iconMap)
        .includes(this.status)
        ? iconMap[this.status]
        : iconMap.default;
    },
  },
};
</script>
