var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1 fill-height",attrs:{"items":_vm.items,"headers":_vm.headers,"items-per-page":_vm.itemsPerPage,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('activity-table-status-cell',{attrs:{"status":item.status}})]}},{key:"item.item",fn:function(ref ){
var item = ref.item;
return [_c('activity-table-item-cell',{attrs:{"item":item}})]}},{key:"item.ethPrice",fn:function(ref){
var item = ref.item;
return [_c('currency-display',{staticClass:"text-center",attrs:{"token":"eth","value":item.ethPrice}})]}},{key:"item.from",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.from)+" ")]}},{key:"item.to",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.to)+" ")]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }